import './MyPage.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons";
import {_ProfileRow} from "./_ProfileRow";
import Footer from "../../../component/footer/Footer";
import React, {useEffect, useState} from "react";
import axios from "axios";

function MyPage() {
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axios.get("https://air.aiia-gcu.com/user/myInfo", {
                    withCredentials: true
                });
                if (response.data.statusCode === 200) {
                    const data = response.data.data;
                    setProfileData({
                        nickname: data.name,
                        studentId: data.grade,
                        major: data.major,
                        point: data.point,
                        unit: data.units,
                        position: data.position,
                        email:data.email,
                        phoneNumber: data.phoneNumber,
                    });
                }
            } catch (error) {
                console.error("Error fetching profile data:", error);
            }
        };
        fetchProfileData();
    }, []);

    const profile_data = profileData ? [
        {label: "별명", value: profileData.nickname},
        {label: "학번", value: profileData.studentId},
        {label: "전공", value: profileData.major},
        {label: "번호", value: profileData.phoneNumber},
        {label: "메일", value: profileData.email},
        {label: "유닛", value: profileData.unit},
        {label: "직급", value: profileData.position},
    ] : [];

    return (
        <div className="screen bg-image-gradient">
            <div className="content-wrapper">
                <div className="card-container" style={{width:'380px',height:'520px'}}>
                    <h4 className="h4-txt">내 계정</h4>
                    <FontAwesomeIcon className="fa-circle-user" icon={faCircleUser}/>
                    <p className="modal-txt">{profileData ? profileData.nickname : "로딩 중..."}</p>
                    <div>
                        <span className="sub-calendar-txt">포인트</span>
                        <span className="sub-calendar-txt" style={{color: "var(--bs-primary)"}}>
                            &nbsp;{profileData ? profileData.point + "p" : "로딩 중..."}
                        </span>
                    </div>
                </div>
                <div className="card-container profile-data-container" style={{width:'700px',height:'520px'}}>
                    {profile_data.map((data, index) => (
                        <_ProfileRow key={index} index={index} label={data.label} value={data.value}/>
                    ))}
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default MyPage;
