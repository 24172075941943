import {instance} from "../_base";
import {formatDate} from "../../common/Date";

/**
 * 오늘의 출석표 생성
 *
 * url: /attendance/daily
 * method: POST
 *
 * request: {
 *    date<string>: "YYYY-MM-DD" #query param
 * }
 *
 * response: {
 *    statusCode<number>: 200/400,
 *    statusMessage<string>: "성공"/"실패",
 * }
 */
function createDailyAttendance(date) {
    return new Promise((resolve, reject) => {
        console.log("[API:createDailyAttendance] start");
        const config = {
            params: {date: formatDate(date)}
        };

        instance.post("/attendance/daily", null, config)
            .then(response => {
                console.log("[API:createDailyAttendance] success (200)");
                resolve(response.data);
            })
            .catch(error => {
                console.log(`[API:createDailyAttendance] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

/**
 * 출석 인증 코드 생성하기
 *
 * url: /attendance/code
 * method: POST
 *
 * # query param
 * request: {
 *     openAt<string>: 출석 시작 시각
 *     closeAt<string>: 출석 인증 시작 시각
 *     cutOffAt<string>: 지각 인정 시각
 * }
 *
 * response: {
 *    statusCode<number>: 200/400,
 *    statusMessage<string>: "성공"/"실패",
 *    data<string>: 6자리 출석 코드
 * }
 */


/**
 * 출석 인증 코드 불러오기
 * (출석 인증 코드는 하루에 한 번만 생성 가능
 *  이미 생성된 코드가 있는 경우, 해당 코드를 불러온다)
 *
 * url: /attendance/code/issued
 * method: GET
 *
 * response: {
 *   statusCode<number>: 200/400,
 *   statusMessage<string>: "성공"/"실패",
 *   data<string>: true/false
 * }
 */
function getAttendanceCode(date) {
    return new Promise((resolve, reject) => {
        console.log("[API:getAttendanceCode] start");
        const config = {
            params: { date }
        };

        instance.get("/attendance/code", config)
            .then(response => {
                console.log("[API:getAttendanceCode] success (200)");
                resolve(response.data);
            })
            .catch(error => {
                console.log(`[API:getAttendanceCode] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

/**
 * 특정 날짜의 출석자들의 출석 상태 조회
 *
 * url: /attendance/daily
 * method: GET
 *
 * # query param
 * request: {
 *   date<string>: "YYYY-MM-DD"
 * }
 *
 * response: {
 *  statusCode<number>: 200/400,
 *  statusMessage<string>: "성공"/"실패",
 *  data: [
 *    {
 *      userId<number>,
 *      name<string>,
 *      status<string>, # "ATTENDANCE"/"LATE"/"ABSENCE"
 *      note<string>
 *    },
 *    ...
 *  ]
 * }
 */
function getUserListToAttend({date}) {
    return new Promise((resolve, reject) => {
        console.log("[API:getUserListToAttend] start");
        let config = {
            params: {
                date: date
            }
        }
        instance.get("/attendance/daily", config)
            .then((response) => {
                console.log("[API:getUserListToAttend] success (200)", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`[API:getUserListToAttend] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

/**
 * 툭종 잘짜 출석 상태 일괄 수정
 *
 * url: /attendance/daily
 * method: PUT
 *
 * request: {
 *  "date": "2024-12-21",
 *  "attendanceList": [
 *      {
 *          "userId": 1,
 *          "status": "ATTENDANCE",
 *          "note": "정상 출석"
 *      },
 *      ...
 *  ]
 * }
 *
 * response: {
 *  statusCode<number>: 200/400,
 *  statusMessage<string>: "성공"/"실패",
 * }
 */
function updateAttendanceStatus({date, attendanceList}) {
    return new Promise((resolve, reject) => {
        console.log("[API:updateAttendanceStatus] start");
        let config = {
            params: {
                date: date
            }
        }
        instance.put(
            "/attendance/daily",
            {attendanceList: attendanceList},
            config
        )
            .then((response) => {
                console.log("[API:updateAttendanceStatus] success (200)", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`[API:updateAttendanceStatus] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

/**
 * 특정 날짜의 출석자 명단 조회
 *
 * url: /attendance/required
 * method: GET
 *
 * # query param
 * request: {
 *  date<string>: "YYYY-MM-DD"
 * }
 *
 * response: {
 *  statusCode<number>: 200/400,
 *  statusMessage<string>: "성공"/"실패",
 *  "data": {
 *      "react": [
 *          {
 *              "userId": 1,
 *              "name": "사용자1",
 *              "isAttendanceRequired": true
 *          },
 *          ...
 *      ],
 *      "spring": [
 *          {
 *              "userId": 2,
 *              "name": "사용자2",
 *              "isAttendanceRequired": false
 *          }
 *          ...
 *      ]
 *  }
 * }
 */
function getRequiredUserList({date}) {
    return new Promise((resolve, reject) => {
        console.log("[API:getRequiredUserList] start");
        let config = {
            params: {
                date: date
            }
        }
        instance.get("/attendance/required", config)
            .then((response) => {
                console.log("[API:getRequiredUserList] success (200)\n", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`[API:getRequiredUserList] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

/**
 * 특정 날짜의 출석자 명단 수정
 *
 * url: /attendance/required
 * method: PUT
 *
 * request: {
 *  "date": "2024-12-21",   # query param
 *  "attendanceRequiredStatus": [
 *      {
 *          "userId": 1,
 *          "isAttendanceRequired": true
 *      },
 *      ...
 *  ]
 * }
 *
 * response: {
 *  statusCode<number>: 200/400,
 *  statusMessage<string>: "성공"/"실패",
 * }
 */
function updateRequiredUserList({date, attendanceRequiredStatus}) {
    return new Promise((resolve, reject) => {
        console.log("[API:updateRequiredUserList] start");
        instance.put(
            "/attendance/required",
            {
                date: date,
                attendanceRequiredStatus: attendanceRequiredStatus
            },
        )
            .then((response) => {
                console.log("[API:updateRequiredUserList] success (200)");
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`[API:updateRequiredUserList] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

/**
 * 전체 유저의 출석률 조회
 *
 * url: /attendance/rate
 * method: GET
 *
 * response: {
 *  "statusCode": 200,
 *  "statusMessage": "성공",
 *  "data": [
 *      {
 *          "userId": 1,
 *          "name": "사용자1",
 *          "attendanceCount": 10,
 *          "lateCount": 2,
 *          "absenceCount": 3,
 *          "attendanceRate": 80
 *      }
 *  ]
 * }
 */
function getAttendanceRate() {
    return new Promise((resolve, reject) => {
        console.log("[API:getAttendanceRate] start");
        instance.get("/attendance/rate")
            .then((response) => {
                console.log("[API:getAttendanceRate] success (200)");
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`[API:getAttendanceRate] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

export {
    createDailyAttendance,
    getAttendanceCode,
    getUserListToAttend,
    updateAttendanceStatus,
    getRequiredUserList,
    updateRequiredUserList,
    getAttendanceRate
};