class UserModel {
    constructor({ id, username, name, profileImage, grade, major, position, point, role, authorities, units, email, phoneNumber}) {
        this.id = id;
        this.username = username;
        this.name = name;
        this.profileImage = profileImage;
        this.grade = grade;
        this.major = major;
        this.position = position;
        this.point = point;
        this.role = role;
        this.authorities = authorities;
        this.units = units;
        this.email = email;
        this.phoneNumber = phoneNumber;
    }

    static fromObject(obj) {
        return new UserModel({
            id: obj.id ?? null,
            username: obj.username ?? null,
            name: obj.name ?? null,
            profileImage: obj.profileImage ?? null,
            grade: obj.grade ?? null,
            major: obj.major ?? null,
            position: obj.position ?? null,
            point: obj.point ?? null,
            role: obj.role ?? null,
            authorities: obj.authorities ?? [],
            units: obj.units ?? [],
            email: obj.email ?? null,
            phoneNumber: obj.phoneNumber ?? null,
        });
    }

    toJson() {
        return JSON.stringify({
            id: this.id,
            username: this.username,
            name: this.name,
            profileImage: this.profileImage,
            grade: this.grade,
            major: this.major,
            position: this.position,
            point: this.point,
            role: this.role,
            authorities: this.authorities,
            units: this.units,
            email:this.email,
            phoneNumber: this.phoneNumber,
        });
    }

    isAdmin() {
        return this.role === "ADMIN";
    }
}

export { UserModel };