import React from "react";
import TableCell from "@mui/material/TableCell";

export function rowContent(_index, row, attributes, setContent) {
    return (
        <React.Fragment>
            {attributes.map((attribute) => (
                <TableCell
                    key={attribute.dataKey}
                    className="body3-txt"
                    align={attribute.align}
                    style={{
                        padding: 11,
                        borderBottom: "1px solid var(--not-selected)"
                    }}>
                    {setContent(_index, row, attribute)}
                </TableCell>
            ))}
        </React.Fragment>
    );
}