import {createSlice} from "@reduxjs/toolkit";
import {fetchUserData, registerUserAction} from "./UserManagementAction";
import {ActionType} from "../../_ActionType";

export function UserManagementReducer() {
    return createSlice({
        name: "admin/user",
        initialState: {
            users: [],
            changedUsers: [],
            status: ActionType.IDLE,
            error: null
        },
        extraReducers: (builder) => {
            builder
                .addCase(fetchUserData.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(fetchUserData.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.users = action.payload;
                })
                .addCase(fetchUserData.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                })

            builder
                .addCase(registerUserAction.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(registerUserAction.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                })
                .addCase(registerUserAction.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });
        }
    });
}