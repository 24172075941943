
class UnitEnum {
    static values = [
        "Flutter",
        "React",
        "Spring",
        "AI R&D",
        "Design"
    ];

    static getValue = (key) => {
        if (key === "Flutter") return 0;
        if (key === "React") return 1;
        if (key === "Spring") return 2;
        if (key === "AI R&D") return 3;
        if (key === "Design") return 4;
        throw new Error("Invalid key");
    }

    static getKey = (value) => {
        if (value === 0) return "Flutter";
        if (value === 1) return "React";
        if (value === 2) return "Spring";
        if (value === 3) return "AI R&D";
        if (value === 4) return "Design";
        throw new Error("Invalid value");
    }
}

export default UnitEnum;