import {createAsyncThunk} from "@reduxjs/toolkit";
import {checkCode, fetchDailyAttendance, fetchMonthlyAttendance} from "../../../api/user_side/AttendanceAPI";

const fetchAttendanceAction = createAsyncThunk(
    'attendance/status/monthly',
    async (action) => {
        try {
            console.log("[Attendance] action: fetchAttendance (start)");

            // Todo. 현재 API에서 request로 year, month를 받지 않음 => 수정 필요
            const response = await fetchMonthlyAttendance();
            console.log(`[Attendance] action: fetchAttendance (done)\n`, response);

            return response.data;
        } catch (e) {
            console.log("[Attendance] action: fetchAttendance (error)");
            console.log(e);
        }
    }
);

const fetchTodayStatusAction = createAsyncThunk(
    'attendance/status/today',
    async (action) => {
        try {
            console.log("[Attendance] action: fetchTodayStatus (start)");

            const response = await fetchDailyAttendance();
            console.log(`[Attendance] action: fetchTodayStatus (done)\n`, response);

            return response.data.status;
        } catch (e) {
            console.log("[Attendance] action: fetchTodayStatus (error)");
            console.log(e);
        }
    }
);


const requestCheckCodeAction = createAsyncThunk(
    "attendance/check",
    async (arg, { rejectWithValue }) => {
        try {
            console.log("[Attendance] action: requestCheckCode (start)");

            const request = { code: arg.code };
            const response = await checkCode(request);

            console.log(`[Attendance] action: requestCheckCode (done)\n`, response);
            if (response.statusCode === 200) {
                return true;
            }
            return rejectWithValue("코드가 일치하지 않습니다.");
        } catch (error) {
            console.error("[Attendance] action: requestCheckCode (error)", error);
            return rejectWithValue("출석 처리 중 오류가 발생했습니다.");
        }
    }
);

export {
    fetchAttendanceAction,
    fetchTodayStatusAction,
    requestCheckCodeAction
}