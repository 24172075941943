import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserData} from "../../../../redux/admin_service/user_management/UserManagementAction";
import {CustomUT} from "./_CustomUT";

function UserTablePage() {
    const dispatch = useDispatch();

    const selector = useSelector((state) => state.userManagement);
    const data = selector.users;

    useEffect(() => {
        dispatch(fetchUserData());
    }, [dispatch]);

    return (
        <div style={{
            width: "100%",
            height: "100%",
            padding:
                "0 " +
                "calc(88 * var(--bs-aspect-ratio-width)) " +
                "calc(100 * var(--bs-aspect-ratio-height)) " +
                "calc(88 * (var(--bs-aspect-ratio-width)))",
            display: "flex",
            flexDirection: "column",
            zIndex: 0
        }}>
            <h4
                className="h4-txt"
                style={{color: "var(--bs-primary)", lineHeight: "1.5"}}>
                멤버 전체 관리
            </h4>
            <CustomUT data={data} />
        </div>
    );
}

export default UserTablePage;