
class RoleEnum {
    static values = [
        "기장",
        "총괄",
        "운영진",
        "정규 부원",
        "신입 회원"
    ];

    static getValue = (key) => {
        if (key === "기장") return 0;
        if (key === "총괄") return 1;
        if (key === "운영진") return 2;
        if (key === "정규 부원") return 3;
        if (key === "신입 회원") return 4;
        throw new Error("Invalid key");
    }

    static getKey = (value) => {
        if (value === 0) return "기장";
        if (value === 1) return "총괄";
        if (value === 2) return "운영진";
        if (value === 3) return "정규 부원";
        if (value === 4) return "신입 회원";
        throw new Error("Invalid value");
    }
}

export default RoleEnum;