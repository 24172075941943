import './Footer.css'
import styled from "styled-components";

const StyledPhrases = styled.p`
    height: calc(100 * var(--bs-aspect-ratio-height));
    margin: 0;
    color: var(--signup-au);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

function Footer({ sidePosition }) {
    return(
        <div
            className="Footer"
            // style={{
            //     background: !sidePosition ? null : "none",
            //     position: !sidePosition ? null : "relative",
            // }}
        >
            <StyledPhrases
                className="body3-txt"
                style={{
                    paddingLeft: sidePosition && 300,
                }}>
                2024 ⓒ AIIA. All rights reserved.
            </StyledPhrases>
        </div>
    );
}

export default Footer;