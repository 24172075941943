import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWeekEvents } from '../../../redux/user_service/calendar/UserCalendarAction';

const SubCalendarContainer = styled.div`
    margin-left: calc(108 * var(--bs-aspect-ratio-width));
    margin-bottom: calc(80 * var(--bs-aspect-ratio-height));
    border-left: 3px solid var(--blue1);
    padding-left: 30px;
`;

function SubCalendar() {
    const dispatch = useDispatch();
    const weekEvents = useSelector((state) => state.calendar.weekEvents);

    const startDate = moment().startOf('isoWeek');
    const weekCalendar = useMemo(
        () => [...Array(7)].map((_, i) => startDate.clone().add(i, 'days')),
        [startDate]
    );

    useEffect(() => {
        dispatch(fetchWeekEvents());
    }, [dispatch]);

    const getEventsForDay = (day) => {
        return weekEvents[day.format('YYYY-MM-DD')] || [];
    };

    const formatEventTime = (startTime, endTime) => {
        // 00:00 ~ 23:59로 설정된 경우 "시간 미정" 표시함
        if (startTime === '00:00' && endTime === '23:59') {
            return '시간 미정';
        }
        return `${startTime} - ${endTime}`;
    };

    return (
        <Col>
            {weekCalendar.map((day, index) => (
                <SubCalendarContainer key={index}>
                    <div className="btn-txt" style={{ color: 'var(--sub-title5)' }}>
                        {day.format('ddd, D MMM')}
                    </div>
                    <div style={{ marginTop: 'calc(40 * var(--bs-aspect-ratio-height))' }}>
                        {getEventsForDay(day).map((event, idx) => {
                            const startTime = event.startTime ? moment(event.startTime).format('HH:mm') : null;
                            const endTime = event.endTime ? moment(event.endTime).format('HH:mm') : null;

                            return (
                                <div className="sub-calendar-txt" style={{ color: 'var(--sub-title5)' }} key={event.id || idx}>
                                    {event.title}
                                    <div className="caption-txt" style={{ color: 'var(--airblue)' }}>
                                        {startTime && endTime
                                            ? formatEventTime(startTime, endTime)
                                            : '시간 미정'}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </SubCalendarContainer>
            ))}
        </Col>
    );
}

export default SubCalendar;
