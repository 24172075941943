import {configureStore} from "@reduxjs/toolkit";
import {UserReducer} from "./user_service/user/UserReducer";
import {NewsReducer} from "./user_service/news/NewsReducer";
import {UserAttendanceReducer} from "./user_service/attendance/UserAttendanceReducer";
import {AttendanceManagementReducer} from "./admin_service/attendance_management/AttendanceManagementReducer";
import {UserManagementReducer} from "./admin_service/user_management/UserManagementReducer";
import calendarReducer from "./user_service/calendar/UserCalendarReducer";
export default configureStore({
    reducer: {
        user: UserReducer().reducer,
        calendar:calendarReducer,
        news: NewsReducer().reducer,
        attendance: UserAttendanceReducer().reducer,
        attendanceManagement: AttendanceManagementReducer().reducer,
        userManagement: UserManagementReducer().reducer
    },
});