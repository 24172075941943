import axios from "axios";
import {instance} from "../_base";

/**
 * 로그인
 *
 * url: /auth/login
 * method: POST
 *
 * request: {
 *    username<string>: 로그인 ID,
 *    password<string>: 로그인 PW
 * }
 *
 * response: {
 *     success<boolean>: 로그인 성공 여부,
 *     message<string>: 메시지
 *     payload<string>: 추가 데이터
 * }
 * * cookie: session
 *
 * return: response.data
 */
function login({ username, password }) {
    return new Promise((resolve, reject) => {
        console.log("[API:login] start");
        let params = {
            "username": username,
            "password": password
        };
        instance.post("/auth/login", params)
            .then((response) => {
                console.log("[API:login] success (200)");
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`[API:login] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

function logout() {
    return new Promise((resolve, reject) => {
        console.log("[API:logout] start");
        instance.post("/auth/logout")
            .then((response) => {
                console.log("[API:logout] success (200)");
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`[API:logout] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

export {
    login,
    logout
};