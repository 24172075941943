import React from 'react';
import dayjs from 'dayjs';

const CalendarList = ({ events, date }) => {
    const dateKey = dayjs(date).format('YYYY-MM-DD');
    const eventList = events[dateKey] || [];

    return (
        <div className="event">
            {eventList.map((event, idx) => (
                <div key={idx}>{event.title.slice(0, 4)}</div>
            ))}
        </div>
    );
};

export default CalendarList;
