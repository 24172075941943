import React from 'react';
import './TopBar.css';
import {Container, Nav, Navbar} from "react-bootstrap";
import {ImageAssets} from "../../common/Image";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function TopBar({ isAdminPage }) {
    const navigate = useNavigate();

    const isLoggedIn = !!sessionStorage.getItem("token");
    const isAdmin = sessionStorage.getItem("role")?.toUpperCase() === "ADMIN";

    const handleLogout = async () => {
        try {
            const token = sessionStorage.getItem("token");
            if (!token) {
                alert("로그인 상태가 아닙니다.");
                return;
            }

            const response = await axios.post(
                "https://air.aiia-gcu.com/auth/logout",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true
                }
            );

            if (response.data.success) {
                alert(response.data.message);
                sessionStorage.clear();
                navigate("/");
            } else {
                console.error("Logout failed:", response.data.message);
                alert("로그아웃 실패: " + response.data.message);
            }
        } catch (error) {
            console.error("Error logging out:", error);
            alert("로그아웃 중 오류가 발생했습니다.");
        }
    };



    return (
        <Navbar className="TopNav">
            <Container className="TopNav-Content">
                <Navbar.Brand as={Link} to={isAdminPage ? "/admin/attendance/calendar" : "/"}>
                    <img
                        className="Logo"
                        src={ImageAssets.logo_aiia}
                        alt="logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="body2-txt">
                    {isAdminPage ? (
                        <>
                            <Nav className="Menu">
                                <img
                                    src={ImageAssets.icon_admin}
                                    alt="admin"
                                    width="40px"
                                    style={{ marginLeft: 16 }}
                                />
                                <Nav className="Text" style={{ color: "var(--airblue)", marginLeft: 16 }}>
                                    관리자 페이지
                                </Nav>
                            </Nav>
                            <Nav className="Actions">
                                <Nav.Link as={Link} className="Text" to="/">
                                    Main Home
                                </Nav.Link>
                            </Nav>
                        </>
                    ) : (
                        <>
                            <Nav className="Menu">
                                <Nav.Link as={Link} className="Text" to="/member">
                                    멤버정보
                                </Nav.Link>
                                {/*<Nav.Link as={Link} className="Text" to="/news">*/}
                                {/*    소식광장*/}
                                {/*</Nav.Link>*/}
                                {isLoggedIn && (
                                    <>
                                        <Nav.Link as={Link} className="Text" to="/calendar">
                                            일정관리
                                        </Nav.Link>
                                        <Nav.Link as={Link} className="Text" to="/attendance">
                                            출석체크
                                        </Nav.Link>
                                    </>
                                )}
                            </Nav>
                            <Nav className="Actions">
                                {isLoggedIn ? (
                                    <>
                                        <Nav.Link
                                            as={Link}
                                            className="Text"
                                            to="/"
                                            onClick={handleLogout}
                                        >
                                            로그아웃
                                        </Nav.Link>
                                        <Nav.Link as={Link} className="Text" to="/me">
                                            <img
                                                src={ImageAssets.icon_profile}
                                                alt="profile"
                                                className="Nav-Icon"
                                            />

                                        </Nav.Link>
                                        {isAdmin && (
                                            <Nav.Link
                                                as={Link}
                                                className="Text"
                                                to="/admin/attendance/calendar"
                                            >
                                                <img
                                                    src={ImageAssets.icon_admin}
                                                    alt="admin"
                                                    className="Nav-Icon"
                                                />
                                            </Nav.Link>
                                        )}
                                    </>
                                ) : (
                                    <Nav.Link as={Link} className="Text" to="/login">
                                        로그인
                                    </Nav.Link>
                                )}
                            </Nav>
                        </>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopBar;
