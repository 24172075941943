const AttendanceStatus = {
    PRESENT: "출석",
    ABSENT: "결석",
    LATE: "지각"
};

const names = [
    '김차천', '용용이', '레오나', '엄준식', '한가우', '석자', '오덕훈'
];

const notes = [
    '',
    '가면라이더 시리즈에 등장하는 전사(히어로)의 호칭. 본래는 초대 가면라이더의 \'가면라이더 1호 & 2호\'를 부르는 호칭이었지만, 갈수록 늘어나는 시리즈에서 등장하는 모든 라이더들을 \'가면라이더\'라고 부르게 되었다. 과거에는 라이더라는 명칭 답게 바이크 형태인 자신만의 전용 비클을 사용하는 것이 일반적이었으나, 이후 서브 라이더나 다크 라이더와 같이 바이크나 비클을 사용하지 않는 라이더도 등장하기 시작했으며, 헤이세이 라이더 후반기에 들어서는 라이드 스트라이커나 디아고 스피디처럼 1호 라이더도 비클이 설정상으로 존재하긴 한데, 극초반 사용 이후 사용하지 않는 경우도 늘었다.',
    '',
    '',
    '',
    '코알라는 은근히 성깔 있는 동물이다. 코알라 다큐멘터리나 현지인이 코알라를 포착한 영상을 보면 생각보다 거칠게 구는 경우가 많다. 특유의 울음소리와 섞이면 그 시너지는 장난이 아니다. 이렇게 싸우는 이유는 보통 서식지 침범이나 떠돌이 수컷이 지역 보스급의 수컷인 알파메일(Alpha-Male)[9]에게 도전하거나, 아무도 없는 나무인 줄 알았더니 이미 주인이 있어서 으르렁거리며 싸우거나, 만만한 개체를 공격하여 내쫓고 자기가 집주인이 되는 경우도 있다. 암컷도 마냥 온순하지만은 않은데 자기가 원하지 않는 타이밍에 수컷이 짝짓기를 위해 접근할 경우 날카롭게 울면서 오지 말라고 위협하는 제스처를 취한다. 보통 번식은 보스라 할 수 있는 수컷이 주변의 암컷을 독점하는 경우가 많다. 이런 일부다처제 방식의 번식성 때문에 후술할 코알라에게 위협적인 질병인 클라미디아와 레트로바이러스가 야생 코알라의 생태계에 창궐하는 데 한몫한다.',
    '',
    '',
    '트럼프는 취임 첫날 NAFTA 재협상이나 탈퇴를 선언하는 등 첫 200일간 미국의 무역정책을 구조조정하는 \'200일 계획\'을 정권인수위원회가 마련했다고 한다. CNN은 이러한 내용을 담은 인수위 메모를 공개했다.# 메모는 "트럼프의 무역 관련 계획은 공화당과 민주당 양당의 세계화 세력들과 절연한다"며 "트럼프 행정부는 수십 년의 유화적 무역정책을 뒤집으며, 새로운 무역협정들은 미국 노동자와 기업의 이익을 최우선으로 제공하는 방향으로 협상할 것"이라고 밝혔다. 200일 계획은 5개의 주요원칙에 기반을 뒀다.,',
    '',
    '',
    '',
    '',
    '하츠네 미쿠(初音ミク)는 일본의 야마하가 개발한 음성 합성 소프트웨어 VOCALOID를 사용해서 크립톤 퓨처 미디어사가 개발한 음원이다.[8] 2007년 8월 31일에 발매되었다. 2024년 8월 31일자로 17주년을 맞이하면서 미쿠의 설정 나이와 1년이 높게 되었다. 이름은 "아직 보지 않은 미래에서, 처음의 소리가 찾아오다(まだ見ぬ未来[9]から、初めての音がやって来る)에서 착안했다고 한다. 단, 성인 하츠네는 실존하는 희귀성씨이기도 하며, 일본 전국에 약 50여명 정도가 존재한다고.'
]

const length = 200;

const AllAttendanceSample = () => Array.from({ length }, (_, index) => {
    let randomNumber = Math.floor(Math.random() * length);

    let name = names[randomNumber % names.length];
    let present = Math.floor(Math.random() * 100);
    let late = Math.floor(Math.random() * 100);
    let absent = Math.floor(Math.random() * 100);
    let percent = Math.round(present / (present + late + absent) * 100);

    return {
        id: index,
        name: name,
        present: present,
        late: late,
        absent: absent,
        percent: percent,
        // all: '확인하기'
    };
});

const MonthlyAttendanceSample = () => Array.from({length: 31}, (_, index) => {
    let maxValue = Math.floor(Math.random() * 80) + 20;
    let value = Math.floor(Math.random() * maxValue);
    return { maxValue: maxValue, value: value }
});

const UserAttendanceSample = () => Array.from({length: 31}, (_, index) => {
    let random = Math.floor(Math.random() * 10000);
    return random % 4;
});

export { AttendanceStatus, names, notes, AllAttendanceSample, MonthlyAttendanceSample, UserAttendanceSample };