import React, {useEffect} from "react";
import './AdminViewer.css'
import SideBar from "../../component/side_bar/SideBar";
import {Link, Outlet, useLocation} from "react-router-dom";
import TopBar from "../../component/top_bar/TopBar";
import Footer from "../../component/footer/Footer";
import {createDailyAttendance} from "../../api/admin_side/AttendanceAPI";

function AdminViewer() {
    const location = useLocation();

    const AdminSideBar = () => {
        let menuList = [
            {
                title: "출석 관리",
                list: [
                    {
                        title: "캘린더",
                        link: "/admin/attendance/calendar"
                    },
                    {
                        title: "멤버 출석률",
                        link: "/admin/attendance/list"
                    },
                    {
                        title: "출석 코드 발급",
                        link: "/admin/attendance/code"
                    }
                ]
            },
            {
                title: "회원 관리",
                list: [
                    {
                        title: "전체 멤버",
                        link: "/admin/user/list"
                    },
                    {
                        title: "멤버 등록",
                        link: "/admin/user/register"
                    }
                ]
            }
        ];

        return (
            <SideBar
                width="calc(320 * var(--bs-aspect-ratio-width))"
                padding="
                calc(240 * var(--bs-aspect-ratio-height))
                0
                calc(100 * var(--bs-aspect-ratio-height))
                calc(80 * var(--bs-aspect-ratio-width))">
                {menuList.map((menu, i) => (
                    <div key={i}>
                        <p className="body1-txt side-bar-menu-title">
                            {menu.title}
                        </p>
                        <ul className="side-bar-menu">
                            {menu.list.map((item, i) => {
                                let selected = location.pathname === item.link ? "selected" : null;
                                return (
                                    <li key={`${i}${item.title}`}
                                        className={selected}>
                                        <Link
                                            className={`body1-txt side-bar-menu-txt ${selected}`}
                                            to={item.link}>
                                            {item.title}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ))}
            </SideBar>
        );
    };

    useEffect(() => {
        createDailyAttendance().then((response) => {
            console.log(response);
        })
    }, []);

    return (
        <div
            className="screen bg-gradient"
            style={{display: "flex", flexDirection: "row"}}>
            <TopBar isAdminPage={true}/>

            <AdminSideBar/>

            <div style={{
                width: "calc(1192 * var(--bs-aspect-ratio-width))",
                height: "100%",
                display: "flex",
                paddingTop: "calc(250 * var(--bs-aspect-ratio-height))",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 2
            }}>
                <Outlet/>
            </div>

            <Footer sidePosition/>
        </div>
    );
}

export default AdminViewer;