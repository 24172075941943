import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import {
    createDailyAttendance,
    getAttendanceCode,
    getAttendanceRate,
    getRequiredUserList,
    getUserListToAttend,
    updateAttendanceStatus,
    updateRequiredUserList
} from "../../../api/admin_side/AttendanceAPI";
import dayjs from "dayjs";
import {instance} from "../../../api/_base";

const setFocusedDate = createAction('admin/attendance/setFocusedDate');

const fetchMonthlyAttendanceAction = createAsyncThunk(
    "admin/attendance/monthly",
    async (action) => {
        try {
            console.log("[Attendance] action: fetchAttendance (start)");

            console.log(`[Attendance] action: fetchAttendance (done)`);
            return {};
        }
        catch (e) {
            console.log("[Attendance] action: fetchAttendance (error)");
            console.log(e);
        }
    }
);

const fetchDailyAttendanceAction = createAsyncThunk(
    "admin/attendance/daily",
    async (arg) => {
        try {
            console.log("[Attendance] action: fetchDailyAttendance (start)")

            let request = {date: arg.date};
            let response = await getUserListToAttend(request);

            console.log(`[Attendance] action: fetchDailyAttendance (done)`);

            return response.data.map((item, index) => {
                return {id: index, ...item};
            });
        }
        catch (e) {
            console.log("[Attendance] action: fetchDailyAttendance (error)");
            console.log(e);
            return [];
        }
    }
);

const modifyDailyAttendanceAction = createAsyncThunk(
    "admin/attendance/modifyDaily",
    async (arg) => {
        try {
            console.log("[Attendance] action: modifyDailyAttendance (start)");

            // Todo. 잘 되는지 모르겠음 => 테스트 필요
            let request = {
                date: arg.date,
                attendanceStatus: arg.attendanceStatus,
                note: arg.note
            }
            let response = await updateRequiredUserList(request);

            console.log(`[Attendance] action: modifyDailyAttendance (done)`);
            return response.data;
        }
        catch (e) {
            console.log("[Attendance] action: modifyDailyAttendance (error)");
            console.log(e);
        }
    }
);

const fetchRequiredUserListAction = createAsyncThunk(
    "admin/attendance/userList",
    async (arg) => {
        try {
            console.log("[Attendance] action: fetchRequiredUserList (start)");

            let request = {date: arg.date};
            let response = await getRequiredUserList(request);

            console.log(`[Attendance] action: getAttendanceUserList (done)`);

            if (Object.keys(response.data).length === 0) {
                return {
                    "Flutter": [],
                    "React": [],
                    "Spring": [],
                    "AI R&D": [],
                    "Design": []
                };
            }

            return response.data;
        }
        catch (e) {
            console.log("[Attendance] action: fetchRequiredUserList (error)");
            console.log(e);
        }
    }
);

const modifyRequiredUserList = createAsyncThunk(
    "admin/attendance/updateUserList",
    async (arg) => {
        try {
            console.log("[Attendance] action: updateRequiredUserList (start)");

            let request = {
                date: arg.date,
                attendanceRequiredStatus: arg.attendanceRequiredStatus
            }
            let response = await updateAttendanceStatus(request);
            console.log(`[Attendance] action: updateRequiredUserList (done)\n`, response);

            return null;
        }
        catch (e) {
            console.log("[Attendance] action: updateRequiredUserList (error)");
            console.log(e);
        }
    }
);

const fetchAllAttendanceRateAction = createAsyncThunk(
    "admin/attendance/all",
    async (action) => {
        try {
            console.log("[Attendance] action: fetchAllAttendance (start)");

            let response = await getAttendanceRate();
            console.log(`[Attendance] action: fetchAllAttendance (done)\n`, response);

            return response.data.map((item, index) => {
                return {...item, id: index}
            });
        }
        catch (e) {
            console.log("[Attendance] action: fetchAllAttendance (error)");
            console.log(e);
        }
    }
)

const fetchAttendanceCodeAction = createAsyncThunk(
    "admin/attendance/code",
    async () => {
        try {
            const today = dayjs().format("YYYY-MM-DD");
            const response = await getAttendanceCode(today);
            return response.data;
        } catch (error) {
            console.log("[Attendance] action: fetchAttendanceCode (error)");
            console.log(error);
            return null;
        }
    }
);


const requestAttendanceCodeAction = createAsyncThunk(
    "admin/attendance/create",
    async (params) => {
        try {
            const today = dayjs().format("YYYY-MM-DD");

            // Step 1: 일일 출석부 생성
            const dailyResponse = await createDailyAttendance(today);
            if (dailyResponse?.statusCode !== 200) {
                throw new Error("Daily attendance creation failed");
            }

            // Step 2: 출석 코드 생성 요청
            const codeParams = {
                openAt: params.openAt.toISOString(),
                closeAt: params.closeAt.toISOString(),
                cutOffAt: params.cutOffAt.toISOString(),
                date: today
            };

            const generateCodeResponse = await instance.post("/attendance/code", null, {
                params: codeParams
            });

            if (generateCodeResponse?.data?.statusCode !== 200) {
                throw new Error("Code generation failed");
            }

            // Step 3: 생성된 코드 조회
            const finalResponse = await getAttendanceCode(today);
            return finalResponse.data;

        } catch (error) {
            console.log("[Attendance] action: requestAttendanceCode (error)");
            console.log(error);
            throw error;
        }
    }
);
export {
    setFocusedDate,
    fetchMonthlyAttendanceAction,
    fetchDailyAttendanceAction,
    modifyDailyAttendanceAction,
    fetchRequiredUserListAction,
    modifyRequiredUserList,
    fetchAllAttendanceRateAction,
    fetchAttendanceCodeAction,
    requestAttendanceCodeAction
}