import {createSlice} from "@reduxjs/toolkit";
import {changeUserInfo, tryLogin, tryLogout} from "./UserAction";
import {ActionType} from "../../_ActionType";

export function UserReducer() {
    return createSlice({
        name: "user",
        initialState: {
            data: null,
            status: ActionType.IDLE,
            error: null
        },
        // 비동기 작업 및 외부 액션에 대한 리듀서
        extraReducers: (builder) => {
            // tryLogin
            builder
                .addCase(tryLogin.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(tryLogin.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.data = action.payload;
                })
                .addCase(tryLogin.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });
            // tryLogout
            builder
                .addCase(tryLogout.pending, (state, action) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(tryLogout.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.data = action.payload;
                })
                .addCase(tryLogout.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });

            // changeUserInfo
            builder
                .addCase(changeUserInfo.pending, (state, action) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(changeUserInfo.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.data = action.payload;
                })
                .addCase(changeUserInfo.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });
        }
    });
}