import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Checkbox} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

function UserRow({teamName, usersOfTeam, handleCheckUser, handleCheckTeam}) {
    const [list, setList] = useState( Array(usersOfTeam.length+1).fill(false));

    return (
        <div style={{padding: "20px 0"}}>
            <div style={{marginBottom: 24, display: "flex", alignItems: "center"}}>
                <span>
                    <Checkbox
                        value={list[usersOfTeam.length]}
                        onClick={() => {
                            let changed = !newList[usersOfTeam.length];
                            let newList = Array(list.length).fill(changed);
                            setList(newList);
                            handleCheckTeam();
                        }}
                        sx={{'& .MuiSvgIcon-root': { fontSize: 28 }}}
                    />
                </span>
                <span
                    className="modal-txt"
                    style={{ color: "var(--bs-primary)" }}
                >{teamName}</span>
            </div>

            <Grid container rowSpacing="10px" columnSpacing="20px">
                {usersOfTeam.map((user, index) => (
                    <div key={`${teamName}-${index}`}>
                        <span>
                            <Checkbox
                                value={list[index]}
                                onClick={() => {
                                    handleCheckUser();
                                    let newList = [...list];
                                    newList[index] = !newList[index];
                                    setList(newList);
                                }}
                                sx={{'& .MuiSvgIcon-root': { fontSize: 28 }}}
                            />
                        </span>
                        <span
                            className="body3-txt"
                            style={{color: "var(--dark-gray)"}}
                        >{user.name}</span>
                    </div>
                ))}
            </Grid>
        </div>
    );
}

export function MemberCheckList({
    userList,
    handleCheckUser,
    handleCheckTeam,
}) {
    console.log(userList);
    return (
        <TableContainer
            component={Paper}
            style={{
                marginBottom: 16,
                "--Paper-shadow": "none",
                borderTop: "4px solid var(--bs-primary)",
                borderBottom: "none",
                borderRadius: 0,
            }}
        >
            <Table>
                <TableBody>
                    {Object.keys(userList).map((team, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <UserRow
                                    teamName={team}
                                    usersOfTeam={userList[team]}
                                    handleCheckUser={handleCheckUser}
                                    handleCheckTeam={handleCheckTeam}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


