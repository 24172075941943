import {compareDate} from "../../common/Date";

function AdminAttendanceCalendarTileClassName(date, view, counts) {
    if (view !== 'month') return null;
    if (compareDate(date) > 0 || Object.keys(counts).length === 0) return 'none';

    let data = counts[date.getDate()];
    let percent = data.present / data.total;

    if (percent === 0) return 'bg-0 blue-border';
    if (percent === 1.0) return 'bg-100';
    if (percent >= 0.75) return 'bg-75';
    if (percent >= 0.5) return 'bg-50';
    return 'bg-25';
}

function UserAttendanceCalendarTileClassName(date, view, attendance) {
    const attendanceStatus = attendance.find(item => item.date.toDateString() === date.toDateString());

    if (attendanceStatus) {
        switch (attendanceStatus.status) {
            case "ATTENDANCE":
                return "present"
            case "LATE":
                return "late";
            case "ABSENCE":
                return "absent";
            default:
                return "";
        }
    }
    return "";
}

function ScheduleCalendarTileClassName() {

}

export {
    AdminAttendanceCalendarTileClassName,
    UserAttendanceCalendarTileClassName,
    ScheduleCalendarTileClassName
}