import {UserSample} from "../../../test/data/UserTestData";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {signup} from "../../../api/admin_side/UserAPI";
import RoleEnum from "../../../dto/enum/RoleEnum";
import UnitEnum from "../../../dto/enum/UnitEnum";

/**
 * 유저 정보 조회
 * - 처음 불러올 때 외에도, 새로고침 할 때도 쓰임
 */
const fetchUserData = createAsyncThunk(
    'admin/user/fetch',
    async () => {
        try {
            console.log("[User Management] action: fetch (start)");
            await new Promise((resolve) => setTimeout(resolve, 200));
            let result = [...UserSample];
            console.log("[User Management] action: fetch (done)");
            return result;
        } catch (e) {
            console.log("[User Management] action: fetch (error)");
            console.log(e);
        }
    }
);

/**
 * 새로운 유저 등록
 */
const registerUserAction = createAsyncThunk(
    'admin/user/register',
    async (arg, { rejectWithValue }) => {
        try {
            console.log("[User Management] action: register (start)");

            const request = {
                username: arg.username,
                password: arg.password,
                name: arg.name,
                grade: arg.studentId,
                phoneNumber: arg.phoneNumber,
                email: arg.email,
                major: arg.major,
                position: RoleEnum.getValue(arg.role),
                units: [UnitEnum.getValue(arg.unit)]
            }

            const response = await signup(request);
            console.log("[User Management] action: register (done)");
            return response;
        } catch (error) {
            console.log("[User Management] action: register (error)", error);
            return rejectWithValue(error.message || "회원 등록에 실패했습니다.");
        }
    }
);
//
// /**
//  * 유저 정보 수정
//  */
// const modifyUserDataAction = createAsyncThunk(
//     'admin/user/modify',
//     async (state) => {
//         try {
//             console.log("[User Management] action: modify (start)");
//             state.changedUsers.map(async (user, index) => {
//                 try {
//                     await modifyUser(user.toJSON());
//                     console.log(`[User Management] action: modify (${index}/${state.changedUsers.length} - success)`);
//                 } catch (e) {
//                     console.log(`[User Management] action: modify (${index}/${state.changedUsers.length} - fail)`);
//                 }
//             });
//             console.log("[User Management] action: modify (done)");
//             return await fetchUserData(state);
//         } catch (e) {
//             console.log("[User Management] action: modify (error)");
//             console.log(e);
//         }
//     }
// );
//
// /**
//  * 유저 삭제
//  */
// const deleteUserDataAction = createAsyncThunk(
//     'admin/user/delete',
//     async (state) => {
//         try {
//             console.log("[User Management] action: remove (start)");
//             state.changedUsers.map(async (user, index) => {
//                 try {
//                     await deleteUser(user.toJSON());
//                     console.log(`[User Management] action: remove (${index}/${state.changedUsers.length} - success)`);
//                 } catch (e) {
//                     console.log(`[User Management] action: remove (${index}/${state.changedUsers.length} - fail)`);
//                 }
//             });
//             console.log("[User Management] action: remove (done)");
//             return await fetchUserData(state);
//         } catch (e) {
//             console.log("[User Management] action: remove (error)");
//             console.log(e);
//         }
//     }
// );

export {
    fetchUserData,
    registerUserAction,
    // modifyUserDataAction,
    // deleteUserDataAction
}