import {Route, Routes} from "react-router-dom";
import {AdminRoute} from "./AdminRoute";
import HomePage from "../view/user_viewer/home_page/HomePage";
import UserViewer from "../view/user_viewer/UserViewer";
import AttendancePage from "../view/user_viewer/attendance_page/AttendancePage";
import CalendarPage from "../view/user_viewer/calendar_page/CalendarPage";
import NewsListPage from "../view/user_viewer/news_page/NewsListPage";
import NewsEditorPage from "../view/user_viewer/news_page/NewsEditorPage";
import NewsViewPage from "../view/user_viewer/news_page/NewsViewPage";
import LoginPage from "../view/user_viewer/login_page/LoginPage";
import MyPage from "../view/user_viewer/my_page/MyPage";
import MemberPage from "../view/user_viewer/member_page/MemberPage";

export function GeneralRoute({handleLogin}) {
    return(
        <Routes>
            <Route path="/" element={<UserViewer/>}>
                <Route index path="" element={<HomePage/>}/>
                <Route path="/attendance">
                    <Route path="" element={<AttendancePage/>}/>
                    <Route path="history" element={<div>출석 기록 페이지</div>}/>
                </Route>
                <Route path="/calendar">
                    <Route index element={<CalendarPage/>}/>
                    <Route path=":id" element={<div>일정 상세 페이지</div>}/>
                </Route>
                <Route path="/login" element={<LoginPage handleLogin={handleLogin} />} />
                <Route path="/member" element={<MemberPage/>} />
                <Route path="/me" element={<MyPage/>} />
                <Route path="/news">
                    <Route path="" element={<NewsListPage/>}/>
                    <Route path="edit" element={<NewsEditorPage/>}/>
                    <Route path=":id" element={<NewsViewPage/>}/>
                </Route>
            </Route>
            <Route path="/admin/*" element={<AdminRoute/>}/>
        </Routes>
    );
}