import React, { useState } from 'react';
import "./LoginPage.css";
import { Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

function LoginPage() {
    const navigate = useNavigate();
    const [loginCheck, setLoginCheck] = useState(false);
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const handleLogin = async (event) => {
        event.preventDefault();
        await new Promise((r) => setTimeout(r, 1000));

        try {
            const response = await axios.post(
                "https://air.aiia-gcu.com/auth/login",
                {
                    username: id,
                    password: password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true
                }
            );

            console.log("로그인 응답:", response.data);
            setLoginCheck(false);
            sessionStorage.setItem("token", response.data.token);
            sessionStorage.setItem("username", response.data.username);
            sessionStorage.setItem("role", response.data.payload.role);
            console.log("로그인 성공:" + response.data.username);
            navigate("/");
        } catch (error) {
            console.error("로그인 실패:", error);
            setLoginCheck(true);
        }
    };


    return (
        <div className="parent">
            <div className="login-title">LOGIN</div>
            <div className="sub-text"> AIIA 아이디로 로그인을 해주세요.</div>
            <form onSubmit={handleLogin}>
                <div className="child">
                    <Col >
                        <input className="input-text"
                            type="text"
                            placeholder="아이디 입력"
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                        />
                    </Col>
                    <Col>
                        <input  className="input-text"
                            type="password"
                            placeholder="비밀번호 입력"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Col>
                    {loginCheck && (
                        <label  style={{color: "red"}}>id 혹은 비밀번호가 틀렸습니다.</label>
                    )}
                </div>
                <button type="submit"
                className='login-button'>로그인</button>
            </form>
        </div>
    );
}

export default LoginPage;
