import React, { useState } from 'react';

export function _DotsPagination({focusedIndex, handleClick}) {
    const [selectedIndex, setSelectedIndex] = useState(focusedIndex);

    console.log("re-rendered:", focusedIndex, selectedIndex);
    if (selectedIndex !== focusedIndex) {
        setSelectedIndex(focusedIndex);
    }

    const dotStyle = (index) => {
        return {
            width: `${selectedIndex === index ? 1.2 : 1}em`,
            height: `${selectedIndex === index ? 1.2 : 1}em`,
            borderRadius: "50%",
            marginTop: index === 0 ? 0 : 20,
            padding: 5,
            backgroundColor: selectedIndex === index ? "var(--bs-primary)" : "var(--not-selected)",
            cursor: "pointer",
        }
    };

    return (
        <div
            style={{
                position: "absolute",
                top: "55%",
                left: "7em",
                transform: "translateY(-50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}>
            {[...Array(5)].map((_, index) => (
                <div
                    key={index}
                    onClick={() => {
                        // setSelectedIndex(index);
                        handleClick(index);
                    }}
                    style={dotStyle(index)}
                />
            ))}
        </div>
    );
}