
class PermissionEnum {
    static values = [
        "출석",
        "지각",
        "결석"
    ];

    static getValue = (key) => {
        if (key === "출석") return 0;
        if (key === "지각") return 1;
        if (key === "결석") return 2;
        throw new Error("Invalid key");
    }

    static getKey = (value) => {
        if (value === 0) return "출석";
        if (value === 1) return "지각";
        if (value === 2) return "결석";
        throw new Error("Invalid value");
    }

    static cvtToEN = (key) => {
        if (key === "출석") return "attendance";
        if (key === "지각") return "late";
        if (key === "결석") return "absence";
        throw new Error("Invalid key");
    }
}

export default PermissionEnum;