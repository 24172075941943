export function getLastDayOfMonth(date) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let lastDate = new Date(year, month+1, 0);
    lastDate.setDate(lastDate.getDate() - 1);
    return date.getDay() === lastDate.getDay();
}

export function compareDate(date, date2) {
    let compareDate = date2 ? date2.getTime() : new Date().getTime();
    let diff = date.getTime() - compareDate;

    diff = Math.ceil(diff / (1000 * 60 * 60 * 24));

    if (diff === 0) return 0;
    if (diff > 0) return 1;
    return -1;
}

export function formatDate(date) {
    date = date || new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function formatTime(date) {
    return `${date.getHours()}:${date.getMinutes()}`;
}

export function formatTimestamp(date) {
    return `${formatDate(date)}T${formatTime(date)}`;
}