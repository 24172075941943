import {_UnitContainer} from "./_UnitContainer";
import {_DotsPagination} from "./_DotsPagination";
import {useEffect, useRef, useState} from "react";
import Footer from "../../../component/footer/Footer";

/**
 * 멤버는 아직 하드코딩해야 함
 * 필요한 정보: name, department, role, unit
 */
const data = {
    "Flutter" : [
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
    ],
    "React" : [
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
    ],
    "Spring" : [
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
    ],
    "AI R&D" : [
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
    ],
    "Design" : [
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
        {name: "김민수", department: "컴퓨터공학과", role: "member", unit: ["Flutter"]},
    ],
}

function MemberPage() {
    const [focusedUnitIndex, setFocusedUnitIndex] = useState(0);
    const scrollRef = useRef(null);

    const handleClick = (index) => {
        let keys = Object.keys(data);
        let element = document.getElementById(keys[index]);
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = scrollRef.current.scrollTop + scrollRef.current.offsetTop;
            const sections = Object.keys(data);
            let topOfSections = sections.map(sectionId =>
                document.getElementById(sectionId).offsetTop);
            console.log(topOfSections);

            for (let i = 0; i < sections.length; i++) {
                if (scrollPosition < topOfSections[i]) {
                    setFocusedUnitIndex(i);
                    break;
                }
            }
        };

        const scroll = scrollRef.current;
        scroll.addEventListener("scroll", handleScroll);

        return () => scroll.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="screen bg-image-gradient">
            <div className="member-page" ref={scrollRef}>
                <_DotsPagination
                    focusedIndex={focusedUnitIndex}
                    handleClick={handleClick}
                />
                <div className="scrollable-container">{
                    Object.keys(data).map((unit, index) => (
                        <_UnitContainer key={index} unit={unit} members={data[unit]}/>
                    ))
                }</div>
                <Footer/>
            </div>
        </div>
    );
}

export default MemberPage;