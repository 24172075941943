import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./view/App";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./redux/store.jsx";
import './common/Color.css';
import './common/Font.css';
import './common/Ratio.css';

import dayjs from "dayjs";
import isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ko';
import {CookiesProvider} from "react-cookie";

dayjs.extend(isLeapYear);
dayjs.locale('en');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>
    </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
