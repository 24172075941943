import axios from "axios";
import {Cookies} from "react-cookie";

const BASE_URL = 'https://air.aiia-gcu.com';

export const instance = axios.create({
    baseURL: BASE_URL,
    headers: {'Content-Type': 'application/json',},
    withCredentials: true
});

const _Cookies = new Cookies();

export function setCookie(key, value) {
    _Cookies.set(key, value, {path: '/'});
}

export function getCookie(key) {
    return _Cookies.get(key);
}

export function removeCookie(key) {
    _Cookies.remove(key, {path: '/'});
}