import {createAsyncThunk} from "@reduxjs/toolkit";
import {UserModel} from "../../../dto/model/UserModel";
import {login, logout} from "../../../api/user_side/AuthAPI";

const tryLogin = createAsyncThunk(
    'user/tryLogin',
    async (action, _) => {
        try {
            console.log("[User] action: tryLogin (start)");

            let response = await login({username: "test", password: "test"});
            console.log(response.payload);
            let result = UserModel.fromObject(response.payload);
            console.log("[User] action: tryLogin (done)");
            return result.toJson();
        } catch (e) {
            console.log("[User] action: tryLogin (error)");
            console.log(e);
            throw e;
        }
    }
);

const tryLogout = createAsyncThunk(
    'user/tryLogout',
    async (action, _) => {
        try {
            console.log("[User] action: tryLogout (start)");

            let payload = await logout();
            console.log(payload);

            console.log("[User] action: tryLogout (done)");
            return null;
        } catch (e) {
            console.log("[User] action: tryLogout (error)");
            console.log(e);
        }
    }
);

const changeUserInfo = createAsyncThunk(
    'user/changeUserInfo',
    async (action, _) => {
        try {
            console.log("[User] action: changeUserInfo (start)");

            // Todo. 실제 코드 집어넣기
            await new Promise(resolve => setTimeout(resolve, 200));
            let result = new UserModel({
                point: Math.floor(Math.random() * 1000)
            });


            console.log("[User] action: changeUserInfo (done)");
            return result.toJson();
        } catch (e) {
            console.log("[User] action: changeUserInfo (error)");
            console.log(e);
        }
    }
);

export {
    tryLogin,
    tryLogout,
    changeUserInfo
}