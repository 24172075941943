import React, {useState} from "react";
import {DropdownButton, Dropdown} from "react-bootstrap";
import './DropdownChip.css';

function DropdownChip({
    hint,
    init,
    items,
    onClick
}) {
    const [selectedIndex, setSelectedIndex] = useState(init || -1);

    const handleClick = (index) => {
        setSelectedIndex(index);
        onClick(index);
    };

    return (
        <DropdownButton
            className={`body3-txt ${selectedIndex < 0 ? items[selectedIndex] : items[selectedIndex]}`}
            title={selectedIndex >= 0 ? items[selectedIndex] : hint}
        >
            {
                items.map((item, index) => (
                    <Dropdown.Item
                        key={index}
                        className="profile-modal-category-txt"
                        onClick={() => handleClick(index)}>
                        {item}
                    </Dropdown.Item>
                ))
            }
        </DropdownButton>
    );
}

export default DropdownChip;