import {compareDate} from "../../common/Date";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import AttendanceStatusEnum from "../../dto/enum/AttendanceStatusEnum";

function AdminAttendanceCalendarTileContent(date, view, counts) {
  if (view !== 'month'
      || compareDate(date) > 0
      || Object.keys(counts).length === 0) return null;

  return (
      <div className="calendar-cell body2-txt">
          {counts[date.getDate()].present}명
      </div>
  )
}

function UserAttendanceCalendarTileContent(date, view, attendance) {
    if (view !== 'month' || compareDate(date) > 0) return null;
    let data = attendance.find((obj) => compareDate(obj.date, date) === 0);
    let statusValue = data ? AttendanceStatusEnum.getValue(data.status) : -1;
    const CheckIcon = () => {
        return (
            <FontAwesomeIcon
                icon={faCheck}
                style={{height: 50}}
            />
        )
    }
    console.log('Date:', date, 'Status:', data?.status, 'StatusValue:', statusValue);
    return (
        <div className="calendar-cell body2-txt">
            {
                data === 0 ?  <CheckIcon /> :
                data === 1 ? "지각" :
                data === 2 ? "결석" : ""
            }
        </div>
    )
}

function ScheduleCalendarTileContent({ date, value }) {
  return (
    <div className="calendar-cell">
      <span>{date}</span>
      <span>{value}</span>
    </div>
  );
}

export { AdminAttendanceCalendarTileContent, UserAttendanceCalendarTileContent, ScheduleCalendarTileContent };