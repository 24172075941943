import React, {useEffect, useState} from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import '../../../../component/calendar/Calendar.css';
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {AdminAttendanceCalendarTileClassName} from "../../../../component/calendar/TileClassName";
import {AdminAttendanceCalendarTileContent} from "../../../../component/calendar/TileContent";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchMonthlyAttendanceAction,
    setFocusedDate
} from "../../../../redux/admin_service/attendance_management/AttendanceManagementAction";
import {AttendanceStatus} from "../../../../test/data/AttendanceTestData";

function AttendanceStatisticCalendarPage() {
    const navigator = useNavigate();
    const dispatch = useDispatch();

    const selector = useSelector((state) => state.attendanceManagement);
    const date = new Date(selector.focusedDate);
    const data = selector.monthlyAttendance;
    const counts = {};

    for (let key in data) {
        let total = data[key].length;
        let present = data[key]
            .filter((obj) => obj.status !== AttendanceStatus.ABSENT)
            .length;
        counts[key] = {total: total, present: present};
    }

    const handleChange = (date) => {
        dispatch(setFocusedDate(date.toString()));
        let formattedDate = dayjs(date).format("YYYY-MM-DD");
        navigator("/admin/attendance/calendar/daily?date=" + formattedDate);
    };

    useEffect(() => {
        dispatch(fetchMonthlyAttendanceAction(date));
    }, [dispatch]);

    return (
        <Calendar
            className="calendar"
            locale ="en-US"
            onChange={handleChange}
            next2Label={null}
            prev2Label={null}
            value={date}
            showNeighboringMonth={false}
            tileClassName={({ _, date, view }) =>
                AdminAttendanceCalendarTileClassName(date, view, counts)}
            tileContent={({ date, view}) =>
                AdminAttendanceCalendarTileContent(date, view, counts)}
        />
    );
}

export default AttendanceStatisticCalendarPage;