import {VirtuosoTableComponents} from "../../../../component/table/VirtuosoTableComponents";
import {fixedHeaderContent} from "../../../../component/table/FixedHeaderContent";
import {rowContent} from "../../../../component/table/RowContent";
import {TableVirtuoso} from "react-virtuoso";
import React, {useState} from "react";
import DropdownChip from "../../../../component/dropdown/dropdown_chip/DropdownChip";
import NoteInputForm from "../../../../component/input_form/NoteInputForm";
import {Link} from "react-router-dom";
import {updateAttendanceStatus} from "../../../../api/admin_side/AttendanceAPI";

function TableTitle({ children }) {
    return (
        <div
            className="h4-txt table-title"
            style={{color: "var(--bs-primary)"}}
        >
            {children}
        </div>
    );
}
const statusMap = {
    ATTENDANCE: -1,
    LATE: 1,
    NOT_REQUIRED: 2,
    ABSENCE: 2,
};
const reverseStatusMap = Object.fromEntries(
    Object.entries(statusMap).map(([key, value]) => [value, key])
);

const mapStatusToDropdownIndex = (status) => statusMap[status] || 2;
const mapDropdownIndexToStatus = (index) => reverseStatusMap[index] || "NOT_REQUIRED";



function CustomAT({attributes, data, selectedDate}) {
    const [attendanceData, setAttendanceData] = useState(data);
    const [notes, setNotes] = useState({});

    const handleStatusChange = async (userId, newDropdownIndex) => {
        try {
            const newStatus = mapDropdownIndexToStatus(newDropdownIndex);

            const attendanceList = [{
                userId: userId,
                status: newStatus,
                note: notes[userId] || null
            }];

            await updateAttendanceStatus({
                date: selectedDate,
                attendanceList: attendanceList
            });
            setAttendanceData(prevData =>
                prevData.map(item =>
                    item.userId === userId
                        ? {...item, status: newStatus}
                        : item
                )
            );
        } catch (error) {
            console.error("Failed to update attendance status:", error);
        }
    };

    const handleNoteChange = async (userId, newNote) => {
        try {
            // Find current status for this user
            const currentUserData = attendanceData.find(item => item.userId === userId);
            if (!currentUserData) return;

            // Prepare the request payload
            const attendanceList = [{
                userId: userId,
                status: currentUserData.status,
                note: newNote
            }];

            await updateAttendanceStatus({
                date: selectedDate,
                attendanceList: attendanceList
            });
            setNotes(prev => ({...prev, [userId]: newNote}));
        } catch (error) {
            console.error("Failed to update note:", error);
        }
    };

    const setContent = (_index, row, attribute) => {
        if (attribute.dataKey === 'status') {
            const dropdownIndex = mapStatusToDropdownIndex(row[attribute.dataKey]);

            return (
                <DropdownChip
                    hint="출석"
                    init={dropdownIndex}
                    items={["출석", "지각", "결석"]}
                    onClick={(index) => handleStatusChange(row["userId"], index)}
                />
            );
        }

        if (attribute.dataKey === 'note') {
            return (
                <NoteInputForm
                    value={notes[row["userId"]] || row[attribute.dataKey]}
                    onUpdated={(value) => handleNoteChange(row["userId"], value)}
                />
            );
        }

        if (attribute.dataKey === 'percent') {
            return row[attribute.dataKey] + '%';
        }

        if (attribute.dataKey === 'all') {
            return (
                <Link
                    to='/admin/attendance/list'
                    style={{color: "rgba(0, 0, 0, 0.87)"}}>
                    확인하기
                </Link>
            );
        }

        return row[attribute.dataKey];
    };

    return (
        <TableVirtuoso
            data={attendanceData}
            components={VirtuosoTableComponents}
            fixedHeaderContent={() => fixedHeaderContent(attributes)}
            itemContent={(index, row) => rowContent(index, row, attributes, setContent)}
            style={{
                marginBottom: 16,
                "--Paper-shadow": "none",
                borderTop: "4px solid var(--bs-primary)",
                borderBottom: "none",
                borderRadius: 0,
                height: attendanceData.length > 0 ? "100%" : 70,
            }}
        />
    );
}

export {
    TableTitle,
    CustomAT
}