import {instance} from "../_base";

/**
 * 현재 월의 내 출석 상태 조회
 *
 * url: /attendance/monthly-status
 * method: GET
 *
 * response: {
 *     statusCode<number>: 200/400,
 *     statusMessage<string>: "성공"/"실패",
 *     "data": [
 *          {
 *              "date": "2024-12-01",
 *              "status": "ATTENDANCE"
 *          },
 *          {
 *              "date": "2024-12-02",
 *              "status": "LATE"
 *          },
 *          ...
 *      ]
 * }
 */
function fetchMonthlyAttendance() {
    return new Promise((resolve, reject) => {
        console.log("[API:fetchMonthlyAttendance] start");
        instance.get("/attendance/monthly-status")
            .then((response) => {
                console.log("[API:fetchMonthlyAttendance] success (200)");
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`[API:fetchMonthlyAttendance] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

/**
 * 오늘 내 출석 상태 조회
 *
 * url: /attendance/daily-status
 * method: GET
 *
 * response: {
 *    statusCode<number>: 200/400,
 *    statusMessage<string>: "성공"/"실패",
 *    "data": {
 *        "date": "2024-12-21",
 *        "status": "ATTENDANCE"/"LATE"/"ABSENCE"
 *    }
 * }
 */
function fetchDailyAttendance() {
    return new Promise((resolve, reject) => {
        console.log("[API:fetchDailyAttendance] start");
        instance.get("/attendance/today-status")
            .then((response) => {
                console.log("[API:fetchDailyAttendance] success (200)");
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`[API:fetchDailyAttendance] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

/**
 * 출석 체크
 *
 * url: /attendance/check
 * method: POST
 *
 * # query parameter
 * request: {
 *    code<string>: "123456"
 * }
 *
 * response: {
 *   statusCode<number>: 200/400,
 *   statusMessage<string>: "성공"/"실패",
 * }
 */
function checkCode({code}) {
    return new Promise((resolve, reject) => {
        console.log("[API:checkAttendance] start");
        let config = {
            params: {
                code: code
            }
        }
        instance.post("/attendance/check", {}, config)
            .then((response) => {
                console.log("[API:checkAttendance] success (200)");
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`[API:checkAttendance] error (${error.statusCode})`);
                reject(new Error(error));
            });
    });
}

export {
    fetchMonthlyAttendance,
    fetchDailyAttendance,
    checkCode
};