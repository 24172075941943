import {createSlice} from "@reduxjs/toolkit";
import {fetchAttendanceAction, fetchTodayStatusAction, requestCheckCodeAction} from "./UserAttendanceAction";
import {ActionType} from "../../_ActionType";

export function UserAttendanceReducer() {
    return createSlice({
        name: 'attendance',
        initialState: {
            attendance: [],
            todayStatus: null,
            status: ActionType.IDLE,
            error: null
        },
        extraReducers: (builder) => {
            builder
                .addCase(fetchAttendanceAction.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(fetchAttendanceAction.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.attendance = [...action.payload];
                })
                .addCase(fetchAttendanceAction.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });

            builder
                .addCase(fetchTodayStatusAction.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(fetchTodayStatusAction.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    state.todayStatus = action.payload;
                })
                .addCase(fetchTodayStatusAction.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });

            builder
                .addCase(requestCheckCodeAction.pending, (state, _) => {
                    state.status = ActionType.REQUEST;
                    state.error = null;
                })
                .addCase(requestCheckCodeAction.fulfilled, (state, action) => {
                    state.status = ActionType.SUCCESS;
                    if (action.payload === true) {
                        state.todayStatus = null;
                    }
                })
                .addCase(requestCheckCodeAction.rejected, (state, action) => {
                    state.status = ActionType.FAILURE;
                    state.error = action.payload;
                });
        }
    });
}