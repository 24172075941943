import {Form, InputGroup} from "react-bootstrap";
import React from "react";
import './UserInfoInputForm.css';

function TextInfoInputForm({label, hint, value, onChange}) {
    return (
        <InputGroup>
            <InputGroup.Text
                id="basic-addon1"
                className="profile-modal-title-txt">
                {label}
            </InputGroup.Text>
            <Form.Control
                className="body2-txt"
                placeholder={hint}
                aria-label={hint}
                aria-describedby="basic-addon1"
                value={value}
                onChange={onChange}
            />
        </InputGroup>
    );
}

function DropdownInfoInputForm({label, items, value, onChange}) {
    return (
        <InputGroup>
            <InputGroup.Text
                id="basic-addon1"
                className="profile-modal-title-txt">
                {label}
            </InputGroup.Text>
            <Form.Select
                className="body2-txt"
                aria-label={label}
                value={value}
                onChange={onChange}
                style={{
                    backgroundPosition: "left 18px center",
                    paddingLeft: 44
                }}>
                {
                    typeof value === "undefined" && (
                        <option value={""}>선택</option>
                    )
                }
                {items.map((item, index) => (
                    <option key={index} value={item}>{item}</option>
                ))}
            </Form.Select>
        </InputGroup>
    );
}

function CheckboxInfoInputForm({label, items, value, onChange}) {
    return (
        <InputGroup>
            <InputGroup.Text
                id="basic-addon1"
                className="profile-modal-title-txt">
                {label}
            </InputGroup.Text>
            <div
                style={{
                    width: "calc(100% - 72px)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 20,
                    paddingLeft: 18,
                    backgroundColor: "white",
                    border: "1px solid #dee2e6",
                    borderLeft: "none",
                    borderBottomRightRadius: 8,
                    borderTopRightRadius: 8
                }}>
                {
                    items.map((item, index) => (
                        <Form.Check
                            key={index}
                            className="body3-txt"
                            type="checkbox"
                            label={item}
                            checked={value[item]}
                            onChange={(e) => onChange(item, e.target.checked)}
                            style={{fontSize: 22}}
                        />
                    ))
                }
            </div>
        </InputGroup>
    );
}

export {TextInfoInputForm, DropdownInfoInputForm, CheckboxInfoInputForm};