import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchTodayStatusAction,
    requestCheckCodeAction
} from "../../../redux/user_service/attendance/UserAttendanceAction";
import "../attendance_page/AttendancePage.css";

const AttendanceStatus = {
    ATTENDANCE: "ATTENDANCE",
    LATE: "LATE",
    ABSENT: "ABSENT",
    NONE: "NONE"
};

export function CodeInputForm() {
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    const selector = useSelector((state) => state.attendance);
    const currentStatus = selector.todayStatus || AttendanceStatus.NONE;
    const inputPossible = currentStatus !== AttendanceStatus.ATTENDANCE &&
        currentStatus !== AttendanceStatus.LATE;

    useEffect(() => {
        dispatch(fetchTodayStatusAction());
    }, [dispatch]);

    const getStatusInfo = () => {
        switch (currentStatus) {
            case AttendanceStatus.ATTENDANCE:
                return {
                    icon: faCheck,
                    title: "출석 완료",
                    description: "오늘의 출석이 완료되었습니다.",
                    iconClass: "check-icon success",
                    titleClass: "attendance-title success"
                };
            case AttendanceStatus.LATE:
                return {
                    icon: faClock,
                    title: "지각",
                    description: "출석 시간으로부터 5분 이상 지났습니다.",
                    iconClass: "check-icon late",
                    titleClass: "attendance-title late"
                };
            case AttendanceStatus.ABSENT:
                return {
                    icon: faTimes,
                    title: "결석",
                    description: "금일 출석 대상자가 아니거나,\n입력 시간을 지나 결석 처리된 사용자입니다.",
                    iconClass: "check-icon disabled",
                    titleClass: "attendance-title disabled"
                };
            case AttendanceStatus.NONE:
            default:
                return {
                    icon: faCheck,
                    title: "출석 체크",
                    description: "금일의 출석 코드 번호를 입력해주세요.",
                    iconClass: "check-icon",
                    titleClass: "attendance-title"
                };
        }
    };

    const statusInfo = getStatusInfo();

    const handleCodeChange = (e) => {
        setCode(e.target.value);
        setError("");
    };

    const handleCodeSubmit = async (e) => {
        e.preventDefault();
        if (code.trim() === "") {
            setError("코드를 입력해주세요.");
            return;
        }

        try {
            const result = await dispatch(requestCheckCodeAction({ code })).unwrap();
            if (result) {
                await dispatch(fetchTodayStatusAction());
                setError("유효하지 않은 코드이거나 출석 시간이 지났습니다.");
                return;
            }

            await dispatch(fetchTodayStatusAction());
            setError("");
            setCode("");
        } catch (err) {
            setError("잘못된 출석 코드입니다.");
            console.error(err);
        }
    };
    return (
        <div style={{
            flex: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <div className="attendance-box">
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <FontAwesomeIcon
                        icon={statusInfo.icon}
                        className={statusInfo.iconClass}
                    />
                    <h1 className={statusInfo.titleClass}>
                        {statusInfo.title}
                    </h1>
                    <p className="attendance-description">
                        {statusInfo.description}
                    </p>
                </div>
                <div className="attendance-form-container">
                    <form onSubmit={handleCodeSubmit}>
                        <input
                            className="code-input"
                            type="text"
                            value={code}
                            onChange={handleCodeChange}
                            disabled={!inputPossible}
                            placeholder={inputPossible ? "출석 코드 입력" : "출석 코드 입력 불가"}
                        />
                        {error && <p className="error-message">{error}</p>}
                        <button
                            type="submit"
                            className={`code-submit ${!inputPossible ? 'disabled' : ''}`}
                            disabled={!inputPossible}>
                            입력
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CodeInputForm;