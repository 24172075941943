
export function _ProfileRow({index, label, value}) {
    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            marginTop: index === 0 ? 0 : 32,
        }}>
            <span
                className="profile-card-title-txt"
                style={{
                    display: "flex",
                    flexGrow: 1,
                    fontWeight: "bold",
                    color: "var(--sub-title5)",
                }}>
                {label}
            </span>
            <span
                className="sub-calendar-txt"
                style={{display: "flex", flexGrow: 7}}>
                {value}
            </span>
        </div>
    )
}
